import * as React from "react"

import LayoutWhite from "../components/layoutWhite"

const Contacto_2Page = () => (
  <LayoutWhite
	seccion="contacto-2"	
	title="Contacto"	
	linkTo="experienciaContainer"
	>		
			<div id="whiteContainer" className="row">
				<div className="col-12"
						data-aos='fade-up'
						data-aos-duration="600" data-aos-once="true">
					<h1 className="col">Contacto</h1>
					<h2 className="text-center mt-5">Gracias por ponerse en contacto con nosotros.<br/>
					Nos comunicaremos a la brevedad.</h2>
				</div>			
			</div>
  </LayoutWhite>
)

export default Contacto_2Page